 /*Todo, garbage collect query memory after n queries*/ 
 (function(angular, Fuse, document)
 {
 	if (!window.location.origin)
    {
     window.location.origin = window.location.protocol+"//"+window.location.host;
    }

    angular.module('Autocomplete', ['pasvaz.bindonce'])
    .constant("SETTINGS", {
	    "HIDE_CLASS": 'hidden'
 	  })
    .directive('searchFuse', function($http,$timeout,SETTINGS)
    {
      "use strict";

      return {
          restrict: "A", //attribute or element,
          scope:{
          	inputModel : '=ngModel',
          	submitFunction: '&ngEnter'
          },
          templateUrl: window.location.origin + "/app/kotlar-search-fuse/templates/searchFuseAutoComplete.tpl.html",
          replace: false, /*errors if true & input in template...why!*/
          controller: function ($scope)
          {      
            var vm = this;

            var field = 'hints';

    				if ($scope.searchByAuthor)
    				{
    					field = 'full_name'; 
    				} else if ($scope.searchByInstitutes){
    					 field = 'institutes'; 
    				} else if ($scope.searchByCompany){
    					 field = 'companies';
    				}

    				var baseUrl = "";

    				if ($scope.searchByAuthor)
            {
    					baseUrl = "http://192.99.46.23:8888/solr/Pubmed_Biomarkers/select?q.op=AND&rows=0&&facet=true&facet.field=full_name_facet&facet.mincount=1&facet.limit=50&wt=json&omitHeader=true&q=full_name:";
    				} 
            else 
            {
    					baseUrl = "http://192.99.46.23:8888/solr/Pubmed_Biomarkers/terms?terms.fl=" + field + "&terms.limit=50&wt=json&omitHeader=true&terms.sort=true&terms.prefix="; //KEN Added 14 Sept, Terms 1
    				}

    				var fuseOptions = {
    				  caseSensitive: false,
    				  includeScore: false,
    				  shouldSort: true,
    				  threshold: 0.6,
    				  location: 0,
    				  distance: 100,
    				  maxPatternLength: 32,
    				  keys: [""]
    				};

            vm.queryMemory = {};

            vm.getMatches = function getMatches(queriedValue)
            {		
            	if(field === 'hints')
            	{	
            		_processPartialQuery(queriedValue);
            	}
            	else
            	{
            		_remoteQuery(queriedValue);
            	}
            	
            };

            /* Expects JSON { terms: hints: [val0, val1...] }
            *@param queriedValue (str) : user's full query
            *@returns void
            *@side-effect: re-assigns $scope.matches the new matches
            */
            var _processPartialQuery = function _processPartialQuery(queriedValue)
            {	
              var splitQuery = queriedValue.split(' ');

              var firstQueryWord = splitQuery[0];

              if(firstQueryWord in vm.queryMemory) //if the prefix was already queried
              {  
              	$scope.matches = _getFuseMatches( queriedValue, vm.queryMemory[ firstQueryWord ] ); //use Fuse to compare first-word matches to the rest of the user's query string
              }
              else
              {
              	_remoteQuery( firstQueryWord ).success( function(prefixMatches)
              	{	
              		//return only even indexes, because odds are filled with the vector sums
              		//done to reduce Levenshtein search space

              		var processedMatches = [];

              		for(var i = 0; i< prefixMatches.terms.hints.length; i+=2)
              		{	
              			processedMatches.push( prefixMatches.terms.hints[i] );
              		}
              		vm.queryMemory[firstQueryWord] = processedMatches ; //store the first word matches to prevent need to requery solr

              		$scope.matches = _getFuseMatches( queriedValue, processedMatches );
              	});
              }
            };

            /*
            *@param queriedValue (str) : user's full query
            *@param hintsToMatchAgainst : hints reutrned from Solr Terms component
            *@requires fuseOptions
            */
            var _getFuseMatches = function _getFuseMatches( queriedValue, hintsToMatchAgainst )
            {	
            	var fuse = new Fuse(hintsToMatchAgainst, fuseOptions );

            	var matches = fuse.search(queriedValue);

            	var matchValuesArray = [];

            	for(var i = 0; i < matches.length; i++)
            	{
            		matchValuesArray.push(hintsToMatchAgainst[ matches[i] ]);
            	}

            	return matchValuesArray;
            };

            var _remoteQuery = function _remoteQuery(queriedValue)
            {
            	return $http.get(baseUrl + queriedValue);
            };
          },
          controllerAs: 'ctrl',
          /* link function role is to trigger controller's getMatches function
          *  and udpate scope.focusState to open/close the matches dropdown
          */
          link: function (scope, element, attrs)
          {	
          	scope.matches = []; //all matches appear as an array

          	scope.focusState = {'hasFocus' : false};

          	var lastInputValue = '';

          	var formControllerElement = element.parent().children('.searchbox-icon');

            scope.onKeypress = function($event)
            {  	
    		   		if($event.which > 48 || $event.which == 46 || $event.which == 8) //  //not perfect doesn't take into f1-12 keys keyCodes 112-122
    		   		{	
    						scope.$evalAsync(function()
    						{ 
    							 	
    							if(lastInputValue == scope.inputModel)
    							{
    								return;
    							}

    							lastInputValue = scope.inputModel;

    							if(!scope.inputModel) // empty no need to query
    							{
    								scope.matches = [];
    							}
    							else
    							{
    								$timeout(function()
    								{
    									scope.ctrl.getMatches(scope.inputModel);
    								},66);	
    							}
    							 
    						} );   			
    		   		}
              else if($event.which == 13) //enter
              {
                  scope.$evalAsync(function()
                  { 
                      scope.focusState.hasFocus = false;
                  });
              }
            };

            scope.onFocus =function() 
            {		            	
            	scope.focusState.hasFocus = true;	
            };

            //update focus state when user types suggestions; occurs when you press enter
            //for this to work scope.matches ref must change; if array being modified in place use $watchCollection
            scope.$watch('matches', function(newMatches, oldMatches)  
            {
                if(newMatches !== oldMatches)
                {
                    scope.focusState.hasFocus = true;
                }
            });

            /*doesn't play well unfortunately; need a way to not cancel scope.updateState
              scope.onBlur = function() 
            {		            	
            	scope.focusState.hasFocus = false;	
        };
              */
              /*todo, figure out how to remove this jquery dependence*/
            $(document).on('click', function(e)
            {
              if( !$(e.target).hasClass('searchbox-input') && !$(e.target).hasClass('hint') )
              {
                scope.$evalAsync(function()
                { 
                    scope.focusState.hasFocus = false;
                });
              }
            });

            scope.updateState = function(matchValue, hintClicked)
            {	
            	scope.inputModel = matchValue;         				

        			if(hintClicked)
        			{
        				$timeout(function()//make sure the model updates before we submit, may be a better way, such as a callback
                	{
                		scope.submitFunction(); 
                		scope.focusState.hasFocus = false;
                	},0);
        			}	
            };
          }
        };
    } );
}(window.angular,window.Fuse,document));